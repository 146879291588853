import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RESTServerRoute } from '../../types/Server';
import { AssetDataResult } from '../../types/DataResult';

@Injectable()
export class AssetsApiService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getAssets(params: { SiteAreaID?: string }): Observable<AssetDataResult> {
    return this.httpClient.get<AssetDataResult>(RESTServerRoute.REST_ASSETS, { params });
  }
}
