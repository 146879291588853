import { Injectable } from '@angular/core';
import { AssetsApiService } from '@api/assets/assets.api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssetDataResult } from '../../types/DataResult';

@Injectable()
export class AssetsRepositoryService {
  public constructor(private readonly assetsApi: AssetsApiService) {}

  public getAssets(params: { SiteAreaID?: string }): Observable<AssetDataResult> {
    return this.assetsApi.getAssets(params);
  }

  public siteAreaContainsAssets(siteAreaID: string): Observable<boolean> {
    return this.getAssets({ SiteAreaID: siteAreaID }).pipe(
      map((assetsDataResult) => assetsDataResult.count > 0),
      catchError(() => of(false)),
    );
  }
}
